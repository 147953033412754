import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogIndex = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const posts = data.allMarkdownRemark.nodes

    if (posts.length === 0) {
        return (
            <Layout location={location} title={siteTitle}>
                <SEO title="All posts" />
                <Bio />
                <p>
                    No blog posts found. Add markdown posts to "content/blog" (or the
                    directory you specified for the "gatsby-source-filesystem" plugin in
                    gatsby-config.js).
                </p>
            </Layout>
        )
    }

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Home" />
            <Bio />
            <ol style={{ listStyle: `none` }}>
                {posts.map(post => {
                    const title = post.frontmatter.title || post.fields.slug
                    const { featuredImage } = post.frontmatter
                    const image = getImage(featuredImage.src)

                    return (
                        <li key={post.fields.slug}>
                            <article
                                className="post-list-item"
                                itemScope
                                itemType="http://schema.org/Article"
                                style={{border: '1px solid #e6e6e6',borderRadius: '4px'}}
                            >
                                <header>
                                    <div>
                                        <Link to={post.fields.slug} itemProp="url" style={{textDecoration:'none'}}>
                                        <GatsbyImage image={image} alt={featuredImage.alt} style={{borderBottom: '1px solid #e6e6e6'}} />
                                            <h2 itemProp="headline" style={{padding: '0.5rem 1rem',letterSpacing: '1px'}}>{title}</h2>
                                        </Link>
                                    </div>
                                    {/*<small className='post-date'>{post.frontmatter.date}</small>*/}
                                </header>
                                <section>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: post.frontmatter.description || post.excerpt,
                                        }}
                                        itemProp="description"
                                        style={{padding: '0.5rem 1rem'}}
                                    />
                                </section>
                            </article>
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: { fields: { draft: { eq: false } } }, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
            src {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                 )
              }
            }
            alt
         }
        }
      }
    }
  }
`
