import * as React from "react"

export const TradeIcon = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z"></path>
    </svg>
)
export const EthereumIcon = () => (
    <svg
        style={{ marginRight: '7px' }}
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 256 417"
        preserveAspectRatio="xMidYMid"
    >
        <path fill="#343434" d="M127.961 0l-2.795 9.5v275.668l2.795 2.79 127.962-75.638z" />
        <path fill="#8C8C8C" d="M127.962 0L0 212.32l127.962 75.639V154.158z" />
        <path fill="#3C3C3B" d="M127.961 312.187l-1.575 1.92v98.199l1.575 4.6L256 236.587z" />
        <path fill="#8C8C8C" d="M127.962 416.905v-104.72L0 236.585z" />
        <path fill="#141414" d="M127.961 287.958l127.96-75.637-127.96-58.162z" />
        <path fill="#393939" d="M0 212.32l127.96 75.638v-133.8z" />
    </svg>
)
export const TwitterIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 400 400"
        width="30"
        height="30"
        style={{ position: 'relative', top: '10px', right: '6px' }}
    >
        <path
            className="cls-2"
            fill="#1da1f2"
            d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23"
        />
    </svg>
)
export const DirectionIcon = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9.01 14H2v2h7.01v3L13 15l-3.99-4v3zm5.98-1v-3H22V8h-7.01V5L11 9l3.99 4z"></path>
    </svg>
)
export const GraphIcon = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M23 23v466h466v-18h-40.893V256h-48v215h-31.675V159.33h-48V471h-31.227V320.242h-48V471H207.2V80.418h-48V471H128V192H80v279H41V23H23z"></path>
    </svg>
)
export const LineGraphIcon = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"></path>
    </svg>
)
export const LibraryIcon = ({ theme }) => {
    const color = theme === 'dark' ? '#ccc' : '#333'
    return (
        <svg strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="none"
                stroke={color}
                strokeWidth="2"
                d="M12,3 L21,7.5 L12,12 L3,7.5 L12,3 Z M16.5,10.25 L21,12.5 L12,17 L3,12.5 L7.5,10.25 L7.5,10.25 M16.5,15.25 L21,17.5 L12,22 L3,17.5 L7.5,15.25 L7.5,15.25"
            ></path>
        </svg>
    )
}
export const CreditIcon = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M4 10v7h3v-7H4zm6 0v7h3v-7h-3zM2 22h19v-3H2v3zm14-12v7h3v-7h-3zm-4.5-9L2 6v2h19V6l-9.5-5z"></path>
    </svg>
)
export const DownloadIcon = () => (
    <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
        <polyline points="7 10 12 15 17 10"></polyline>
        <line x1="12" y1="15" x2="12" y2="3"></line>
    </svg>
)
export const MenuIcon = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
    </svg>
)
export const CloseIcon = (props) => (
    <svg
        {...props}
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>
)
export const BackIcon = () => (
    <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 1024 1024"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M793 242H366v-74c0-6.7-7.7-10.4-12.9-6.3l-142 112a8 8 0 0 0 0 12.6l142 112c5.2 4.1 12.9.4 12.9-6.3v-74h415v470H175c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-28.7 64-64V306c0-35.3-28.7-64-64-64z"></path>
    </svg>
)
export const SearchIcon = ({ theme }) => {
    const isDark = theme === 'dark'
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            fill={isDark ? '#ccc' : 'black'}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        </svg>
    )
}

export const JapanIcon = () => (
    <svg width="1.5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 544.43 363.23">
        <rect width="544.43" height="363.23" rx="55.78" fill="#fff" />
        <circle cx="271.96" cy="181.49" r="108.56" fill="#d71518" />
    </svg>
)

export const EnIcon = () => (
    <svg width="1.5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 45">
        <path
            d="M0 13.8h10L0 7v6.8zM8.3 45h14.8V34.9L8.3 45zm32.6-10.1V45h14.8L40.9 34.9zM0 31.2V38l10-6.8H0zM55.7 0H40.9v10.1L55.7 0zM54 31.2L64 38v-6.8H54zm0-17.4h10V7l-10 6.8zM23.1 0H8.3l14.8 10.1V0z"
            fill="#00247d"
        />
        <path
            d="M44.7 31.2L62 42.9c.8-.8 1.4-1.9 1.8-3L51 31.2h-6.3zm-21.6 0h-3.8L2 42.9c.9.9 2.1 1.6 3.4 1.9l17.6-12 .1-1.6zm17.8-17.4h3.8L62 2.1c-.9-.9-2.1-1.6-3.4-1.9L41 12.2l-.1 1.6zm-21.6 0L2 2.1C1.2 2.9.6 4 .3 5.1l12.8 8.7h6.2z"
            fill="#cf1b2b"
        />
        <path
            d="M64 27.7H37.3V45h3.6V34.9L55.7 45h1.2c1.9 0 3.7-.7 5.1-2.1L44.7 31.2h6.2l12.8 8.7c.2-.6.3-1.2.3-1.8V38l-10-6.8h10v-3.5zm-64 0v3.5h10L0 38v.1c0 1.8.7 3.6 2 4.8l17.3-11.8h3.8v1.6l-17.6 12c.5.2 1.1.3 1.6.3h1.2l14.8-10.1V45h3.6V27.7H0zM64 6.9c0-1.8-.7-3.6-2-4.8L44.7 13.8h-3.8v-1.6L58.5.2C58 .1 57.4 0 56.9 0h-1.2L40.9 10.1V0h-3.6v17.3H64v-3.5H54L64 7v-.1zM23.1 0v10.1L8.3 0H7.1C5.2 0 3.4.7 2 2.1l17.3 11.8h-6.2L.3 5.1C.1 5.7 0 6.3 0 6.9V7l10 6.8H0v3.5h26.7V0h-3.6z"
            fill="#eee"
        />
        <path d="M37.3 17.3V0H26.7v17.3H0v10.4h26.7V45h10.7V27.7H64V17.3H37.3z" fill="#cf1b2b" />
    </svg>
)

export const StarIcon = (props) => (
    <svg
        {...props}
        stroke="currentColor"
        fill="currentColor"
        viewBox="0 0 1024 1024"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z"></path>
    </svg>
)
export const StarFillIcon = (props) => (
    <svg
        {...props}
        stroke="currentColor"
        fill="currentColor"
        viewBox="0 0 1024 1024"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1-43.4 252.9a31.95 31.95 0 0 0 46.4 33.7L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"></path>
    </svg>
)
