import * as React from "react"
import {useStaticQuery, graphql} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import {TwitterIcon} from "./icons";

const Bio = () => {
    const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

    // Set these values by editing "siteMetadata" in gatsby-config.js
    const author = data.site.siteMetadata?.author
    const social = data.site.siteMetadata?.social

    return (
        <div className="bio">
            <StaticImage
                className="bio-avatar"
                layout="fixed"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/profile.png"
                width={50}
                height={50}
                quality={95}
                alt="Profile picture"
            />
            <div>
                <a href={`https://twitter.com/${social?.twitter}`} aria-label='twitter-icon'><TwitterIcon/></a>
                <span>{author?.summary || null}</span>
            </div>
        </div>
    )
}

export default Bio
